import { SPEAKER_ROLE_FOR_SESSION_FRAGMENT } from '@/graphql/_Fragments/SpeakerRole/ForSession';
import { GEOZONE_WITH_EXHIBIT_HALL_FRAGMENT } from '@/graphql/_Fragments/Geozone/WithExhibitHall';

export const SESSION_FOR_AGENDA_FRAGMENT = `
  fragment sessionForAgendaFragment on Session {
    uid
    name
    startTime
    startTimestamp
    endTime
    endTimestamp
    exhibitor {
      uid
      name
      logoFileResource {
        schemaCode
        path
      }
    }
    geozones: _geozonesOfEdition {
      ...geozoneWithExhibitHallFragment
    }
    speakerRoles {
      ...speakerRoleForSessionFragment
    }
    _isInAgenda(myUid: "%authUser%")
    _bookmarkCount
}
${SPEAKER_ROLE_FOR_SESSION_FRAGMENT}
${GEOZONE_WITH_EXHIBIT_HALL_FRAGMENT}
`;
